import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=4d67717f&"
import script from "./Form.vue?vue&type=script&lang=ts&"
export * from "./Form.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QStepper,QStep,QCard,QCardSection,QForm,QInput,QIcon,QStepperNavigation,QBtn,QTable});
