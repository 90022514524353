import { DocumentProfile } from "./businessDocument";
import { AppUser } from "./user";



export interface BusinessApplication {
    businessApplicationID: string;
    reference: string;
    name: string;
    title: string;
    dateCreated: Date;
    dateUpdated: Date;
    dateSubmitted: Date;
    initiator: AppUser;
    status: ApplicationStatus;
    isSubmitted: boolean;
    isSubmittedInitial: boolean;
    isSubmittedFinal: boolean;
    isReviewed: boolean;
    isAppraised: boolean;
    isAppraisalApproved: boolean;
    isLocked: boolean;
    applicationType: string;
    appraisal: string;
    applicationMethod: string;
    reviewProgress: number;
    applicationProgress: number;
    documents: Document[];
    reviewedBy: string;
    appraisedBy: string;
    submittedBy: string;
    type: BusinessType;
    portfolioID?: string;
}
export enum BusinessType {

    SoleTrader,
    Enterprise
}

export enum ApplicationStatus {
    Pending,
    PendingReview,
    SubmittedToCentralBank,
    Reviewed,
    Approved,
    Rejected
}
export interface BusinessProfileVM {
    id: string;
    businessName: string;
    type: BusinessType;
    reference: string;
    status: number;
    businessProfileStatus: string;
    legalProfileStatus: string;
    bankingProfileStatus: string;
    formRStatus: string;
    formJStatus: string;
    additionalProfileStatus: string;
    isIndividual: boolean;
}
export interface ApproveApplicationVM {
    documentId?: string;
    applicationId: string;
    documentProfile?: DocumentProfile;
    type?: DocumentType;
    profileOrder?: number;
}