













































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Loader from "@/helpers/loader";
import Notifier from "@/helpers/notifier";
import agent from "@/helpers/agent";
import {
  ApplicationStatus,
  BusinessApplication,
} from "@/models/businessApplication";
import QForm from 'quasar/src/components/form/QForm.js';import uid from 'quasar/src/utils/uid.js';;
import Portfolio from "@/models/portfolio";

export interface Row {
  rows: BusinessApplication[];
  added: boolean;
  keys: any[];
  evt: any;
}

@Component
export default class Profile extends Vue {
  step = 1;
  portfolio: Portfolio = {
    portfolioApplicationID: "",
    name: "",
    reference: "",
    status: ApplicationStatus.Pending,
    applications: [],
  };
  applications: BusinessApplication[] = [];
  pagination = {
    sortBy: "Name",
    descending: false,
    page: 0,
    rowsPerPage: 10,
    rowsNumber: 10,
  };
  loading = true;
  filter = "";

  selectedApplications: BusinessApplication[] = [];

  columns = [
    {
      name: "Name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: Portfolio) => row.name,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "Reference",
      required: true,
      label: "Reference",
      align: "left",
      field: (row: Portfolio) => row.reference,
      sortable: true,
    },
    {
      name: "Status",
      required: true,
      label: "Status",
      align: "left",
      field: (row: Portfolio) => this.status(row.status),
      format: (val: string) => `${val}`,
      sortable: false,
    },
  ];
  @Watch("step")
  onStepChanged(val: number) {
    this.$router.replace({ query: { ...this.$route.query, step: `${val}` } });
  }

  status(status: ApplicationStatus) {
    return ApplicationStatus[status];
  }
  onSubmit() {
    Loader.showLoader();
    const portfolio = {
      id: this.portfolio.portfolioApplicationID,
      name: this.portfolio.name,
      applications: this.selectedApplications.map(
        (i) => i.businessApplicationID
      ),
    };
    if (this.portfolio.portfolioApplicationID.length === 0) {
      portfolio.id = uid();
      agent.PortFolios.create(portfolio)
        .then(() => {
          Loader.hideLoader();
          Notifier.showPositive(
            `You have succesfully created a business profile`
          );
          this.$router.push({
            name: "FormtPortfolio",
            query: {
              id: portfolio.id,
              name: portfolio.name,
              reference: this.portfolio.reference,
            },
          });
        })
        .finally(() => [Loader.hideLoader()]);
      return;
    }
    agent.PortFolios.update(portfolio)
      .then(() => {
        Loader.hideLoader();
        Notifier.showPositive(
          `You have succesfully updated the business profile`
        );
        this.$router.push({
          name: "FormtPortfolio",
          query: {
            id: this.portfolio.portfolioApplicationID,
            name: this.portfolio.name,
            reference: this.portfolio.reference,
          },
        });
      })
      .finally(() => [Loader.hideLoader()]);
  }
  addApplication(evt: Row) {
    agent.PortFolios.addApplication(
      this.$route.query.id as string,
      evt.rows[0].businessApplicationID,
      evt.added
    ).then(() => {
      Notifier.showPositive(
        `Application successfully ${
          evt.added ? "added" : "removed"
        } to portfolio`
      );
    });
  }
  goToNextStep(formRef: string) {
    const form = this.$refs[formRef] as QForm;
    form.validate().then((success: boolean) => {
      if (success) {
        // yay, models are correct
        switch (this.step) {
          case 3:
            this.onSubmit();
            break;
          default:
            this.step++;
            break;
        }
      } else {
        // oh no, user has filled in
        // at least one invalid value
        Notifier.showError(
          `There are validation errors, please make sure you have filled all the required fields`
        );
      }
    });
  }
  rowClick(row: BusinessApplication) {
    this.$router.push({
      name: "ProfilesApplication",
      query: { id: row.businessApplicationID },
    });
  }
  onRequest(props: any = {}) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const search = props.filter;
    this.loading = true;
    // get all rows if "All" (0) is selected
    const size = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
    agent.Applications.list(search, descending, page, size, sortBy, false).then(
      (rows) => {
        //populate table
        this.applications = rows.rows;
        this.pagination.rowsNumber = rows.rowCount;

        // don't forget to update local pagination object
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        // ...and turn of loading indicator
        this.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  created() {
    if (this.$route.query.step) {
      this.step = parseInt(this.$route.query.step as string);
    }
  }
  async mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
    if (this.$route.query.id) {
      agent.PortFolios.details(this.$route.query.id as string).then((app) => {
        this.selectedApplications = app.applications as BusinessApplication[];
        this.portfolio = app;
      });
    }
  }
}
